<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data">
        <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Clone Name" vid="clone_info_id" rules="required|min_value:1">
                <b-form-group
                label-for="clone_info_id"
                slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                {{$t('teaGardenConfig.clone_name')}} <span class="text-danger">*</span>
                </template>
                <b-form-select
                plain
                v-model="form.clone_info_id"
                :options="masterCloneNameList"
                id="clone_info_id"
                :state="errors[0] ? false : (valid ? true : null)"
                >
                <template v-slot:first>
                    <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
                </b-form-select>
                <div class="invalid-feedback">
                {{ errors[0] }}
                </div>
                </b-form-group>
            </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <ValidationProvider name="Date" vid="date" rules="required">
                <b-form-group
                    label-for="date"
                    slot-scope="{ valid, errors }"
                >
                    <template v-slot:label>
                    {{$t('globalTrans.date')}} <span class="text-danger">*</span>
                    </template>
                    <date-picker
                    id="date"
                    v-model="form.date"
                    class="form-control"
                    :placeholder="$t('globalTrans.select_date')"
                    :state="errors[0] ? false : (valid ? true : null)"
                    :class="errors[0] ? 'is-invalid' : ''"
                    >
                    </date-picker>
                    <div class="invalid-feedback">
                    {{ errors[0] }}
                    </div>
                </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Unit" vid="unit_id" rules="required">
                <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="unit_id"
                    slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                    {{$t('teaGardenBtriService.unit')}} <span class="text-danger">*</span>
                </template>
                <b-form-radio-group
                    id="unit_id"
                    v-model="form.unit_id"
                    :options="unitList"
                    name="unit_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-radio-group>
                <div class="invalid-feedback">
                    {{ errors[0] }}
                </div>
                </b-form-group>
            </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Price" vid="price" rules="required" v-slot="{ errors }">
                <b-form-group
                label-for="price">
                <template v-slot:label>
                    {{ $t('globalTrans.price') }}<span class="text-danger">*</span>
                </template>
                <b-form-input
                    id="price"
                    v-model="form.price"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                    :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                    {{ errors[0] }}
                </div>
                </b-form-group>
            </ValidationProvider>
            </b-col>
        </b-row>
        <b-row class="text-right">
          <b-col>
              <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
              <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { getClonePriceUpdate } from '../../../api/routes'
export default {
  name: 'Form',
  props: ['items'],
  data () {
    return {
      valid: null,
      saveBtnName: this.$t('globalTrans.update'),
      form: {
        id: 0,
        price_info_id: 0,
        unit_id: 0,
        date: '',
        price: 0
      }
    }
  },
  created () {
    if (this.items !== 'undefiend') {
      this.form = this.items
    }
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    masterCloneNameList () {
      return this.$store.state.TeaGardenService.commonObj.masterCloneNameList.filter(item => item.status === 1)
    },
    unitList () {
      let fundList = [
        { value: 1, text_en: 'KG', text_bn: 'কেজি' },
        { value: 2, text_en: 'Piece', text_bn: 'পিস' }
      ]
       fundList = fundList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
      return fundList
    }
  },
  methods: {
    async saveUpdate () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
        result = await RestApi.putData(teaGardenServiceBaseUrl, `${getClonePriceUpdate}/${this.items.id}`, this.form)
      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('TeaGardenService/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-form')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
