<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('teaGardenBtriService.clone_price_info') }} {{ $t('globalTrans.search')}}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
            <b-form-group
              label-for="clone_info_id"
              >
                <template v-slot:label>
                  {{$t('teaGardenConfig.clone_name')}}
                </template>
                <b-form-select
                  plain
                  id="clone_info_id"
                  :options="cloneNameList"
                  v-model="search.clone_info_id"
                >
                  <template v-slot:first>
                    <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <ValidationProvider name="Unit" vid="unit_id" rules="">
                  <b-form-group
                    label-for="unit_id"
                    slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                     {{$t('teaGardenBtriService.unit')}}
                  </template>
                  <b-form-select
                    plain
                    v-model="search.unit_id"
                    :options="sourceOfFund"
                    id="unit_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                  </b-form-group>
                </ValidationProvider>
          </b-col>
          <b-col sm="4">
            <b-button size="sm" variant="primary" class="mt-20" @click="searchData">
              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('teaGardenBtriService.clone_price_info') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction>
        <router-link class="btn-add" to="clone-price-info-add"><i class="ri-add-fill"></i> {{ $t('globalTrans.add_new') }}</router-link>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <select-column-check v-on:editMethodInParent="editMethodInParent" :labelData="labelData" :search="search" :columns="columns" />
          <b-row>
            <b-col md="12" class="table-responsive">
              <slot v-if="columns.filter(item => item.show === '1').length > 0">
                <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                  <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial + pagination.slOffset) }}
                  </template>
                  <template v-slot:cell(date)="data">
                    {{ data.item.date | dateFormat}}
                  </template>
                  <template v-slot:cell(clone_name)="data">
                    {{ currentLocal == 'bn' ? data.item.clone_name?.clone_name_bn : data.item.clone_name?.clone_name_en }}
                  </template>
                  <template v-slot:cell(unit)="data">
                    {{ currentLocal == 'bn' ? data.item.unit_name_bn : data.item.unit_name }}
                  </template>
                  <template v-slot:cell(price)="data">
                    {{ $n(data.item.price) }}
                  </template>
                  <template v-slot:cell(action)="data">
                      <b-button v-b-modal.modal-form variant="iq-bg-success mr-1" size="sm" @click="editMethodInParent(data.item,1)" class="action-btn edit" :title="$t('globalTrans.edit')"><i class="ri-pencil-fill"></i></b-button>
                  </template>
                </b-table>
              </slot>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
               </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
   <b-modal id="modal-form" size="lg" :title="formTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <EditForm :items="items" :key="editItemId"/>
    </b-modal>
  </div>
</template>
<script>
import EditForm from './EditForm.vue'
// import Details from './Details.vue'
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { getClonePriceList } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    EditForm
  },
  data () {
    return {
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
        sortBy: '',
        sortDesc: true,
        sortDirection: 'desc',
        search: {
          clone_info_id: 0,
          unit_id: 0,
          limit: 10
        },
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '8%' } },
        { labels: 'globalTrans.date', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '15%' } },
        { labels: 'teaGardenConfig.clone_name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '15%' } },
        { labels: 'teaGardenBtriService.unit', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '15%' } },
        { labels: 'globalTrans.price', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '15%' } },
        { labels: 'globalTrans.action', class: 'text-left', show: '1', order: 6, thStyle: { width: '12%' } }
      ],
      items: [],
      viewitemId: 0,
      actions: {
        edit: true,
        toogle: true,
        delete: false
      }
    }
  },
  created () {
    this.loadData()
  },
  watch: {
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    }
  },
  computed: {
    currentLocal () {
      return this.$i18n.locale
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'date' },
          { key: 'clone_name' },
          { key: 'unit' },
          { key: 'price' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'date' },
          { key: 'clone_name' },
          { key: 'unit' },
          { key: 'price' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    cloneNameList () {
      return this.$store.state.TeaGardenService.commonObj.masterCloneNameList.filter(item => item.status === 1)
    },
    formTitle () {
      return this.$t('teaGardenBtriService.clone_price_info') + ' ' + this.$t('globalTrans.modify')
    },
    unitList: function () {
        const list = [
          { value: 1, text_en: 'KG', text_bn: 'কেজি' },
          { value: 2, text_en: 'Piece', text_bn: 'পিস' }
        ]
        return list
    },
    sourceOfFund () {
      let fundList = [
         { value: 1, text_en: 'KG', text_bn: 'কেজি' },
         { value: 2, text_en: 'Piece', text_bn: 'পিস' }
      ]
       fundList = fundList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
      return fundList
    }
  },
  methods: {
    editMethodInParent (item, type) {
      this.items = item
    },
    details (item) {
        this.items = item
    },
    searchData () {
      this.loadData()
    },
    getSource (id) {
        this.sourceOfFund.find(item => item.value === id)
    },
    getFiscalYear (yearId) {
        const cateObj = this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1).find(item => item.value === yearId)
        if (cateObj !== undefined) {
            if (this.$i18n.locale === 'bn') {
                return cateObj.text_bn
            } else {
                return cateObj.text_en
            }
        }
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(teaGardenServiceBaseUrl, getClonePriceList, params)
      if (result.success) {
        const listData = result.data.data.map((item, index) => {
          const unitObj = this.unitList.find(tmp => tmp.value === item.unit_id)
           const unitNameObj = {}
            if (typeof unitObj !== 'undefined') {
              unitNameObj.unit_name = unitObj.text_en
                unitNameObj.unit_name_bn = unitObj.text_bn
            } else {
              unitNameObj.unit_name = ''
              unitNameObj.unit_name_bn = ''
            }
          return Object.assign({}, item, unitNameObj, { serial: index })
        })
        this.$store.dispatch('setList', listData)
        this.paginationData(result.data, this.search.limit)
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
